import React, { useState, useContext } from 'react';
import { Container, Button } from 'react-bootstrap';
import { FirebaseContext } from '../../Firebase';
import ImageUploader from 'react-images-upload';
import { useHistory } from 'react-router-dom';
import { withAuthorization, withAuthentication } from '../../Session';
import { compose } from 'recompose';

const UploadFloorplanPage = routeProp => {
  const firebase = useContext(FirebaseContext);
  const [picture, setPicture] = useState(null);
  const networkID = routeProp.match.params.networkId;
  const history = useHistory();

  const resizeImage = blob => {
    var reader = new FileReader();
    reader.onload = function(e) {
      var img = document.createElement('img');
      img.onload = () => {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        var MAX_WIDTH = 800;
        var MAX_HEIGHT = 600;
        var width = img.width;
        var height = img.height;
        console.log(width);
        console.log(height);

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        var dataurl = canvas.toDataURL('image/png');
        console.log(dataurl);
        setPicture(dataurl);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(blob);
  };
  const onDrop = (pictureFiles, pictureDataURLs) => {
    //console.log(picture[0]);
    //console.log(file[0]);
    const newImg = resizeImage(pictureFiles[0]);
    setPicture(newImg);
  };
  const onErase = () => {
    setPicture(null);
  };
  const onUpload = () => {
    if (picture) {
      firebase.setFloorplan(networkID, picture);
      history.push(`/networks/${networkID}/edit`);
    }
    // This edge case shouldn't happen
  };
  return (
    <div>
      <Container>
        <h1> Uploading new floorplan for {networkID} </h1>
        <p>
          {' '}
          Warning: You will need to update sensor placements and
          existing heatmap will be deleted{' '}
        </p>
        <ImageUploader
          withIcon={true}
          buttonText="Choose image"
          label="Max file size: 5mb, accepted: jpg, png"
          onChange={onDrop}
          onErase={onErase}
          singleImage={true}
          withPreview={true}
          imgExtension={['.png']}
          maxFileSize={5242880}
        ></ImageUploader>

        {picture && <Button onClick={onUpload}>Upload</Button>}
      </Container>
    </div>
  );
};
const condition = authUser => !!authUser;

export default compose(
  withAuthentication,
  withAuthorization(condition),
)(UploadFloorplanPage);
