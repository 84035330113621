import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Col,
  Container,
  InputGroup,
  ListGroup,
  FormControl,
  Row,
} from 'react-bootstrap';
import { FirebaseContext } from '../Firebase';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import HeatMapMarker from './Marker';
import LayoutImageView from './Layout';
import { NETWORKS } from '../../constants/routes';
import {
  CheckCircleFill,
  PencilSquare,
  Trash,
  XCircleFill,
} from 'react-bootstrap-icons';
import { withAuthorization, withAuthentication } from '../Session';
import { compose } from 'recompose';

const HeatmapEditPage = (routeProp) => {
  const [loading, setLoading] = useState(true);
  const [camerasList, setCamerasList] = useState([]);
  const [editingCameraNames, setEditingCameraNames] = useState({});
  const firebase = useContext(FirebaseContext);
  //   let { networkId } = useParams();
  //console.log(routeProp);
  const networkID = routeProp.match.params.networkId;
  useEffect(() => {
    //TODO: Make this dynamic

    firebase.networkCameras(networkID).on('value', (snapshot) => {
      const networkCameras = snapshot.val();
      //console.log(networkCameras);
      if (networkCameras === null) {
        setCamerasList([]);
        setLoading(false);
        return;
      }
      const newCamerasList = Object.keys(networkCameras).map(
        (key) => ({
          ...networkCameras[key],
          uid: key,
        }),
      );
      var editingCameraNames = {};
      newCamerasList.forEach((camera) => {
        // Create an object with each id pointing to edit state
        editingCameraNames[camera.id] = {
          editing: false,
          currentName: camera.name,
          newName: camera.name,
        };
      });
      setEditingCameraNames(editingCameraNames);
      setCamerasList(newCamerasList);
      setLoading(false);
    });
  }, []);
  function removeMarker(cameraID) {
    console.log('Remove event');
    console.log(cameraID);
    firebase.unsetCameraLocation(networkID, cameraID);
  }
  function startEditing(cameraID) {
    console.log('Toggle rename');
    console.log(cameraID);
    var editingState = true;
    var newEditingNames = { ...editingCameraNames };
    newEditingNames[cameraID].editing = editingState;
    setEditingCameraNames(newEditingNames);
  }
  function saveEditing(cameraID) {
    //TODO Implement saving the new camera name
    const newName = editingCameraNames[cameraID].newName;
    const currentName = editingCameraNames[cameraID].currentName;
    if (newName !== currentName && newName.length > 0) {
      firebase.setCameraName(networkID, cameraID, newName);
    }

    cancelEditing(cameraID);
  }
  function cancelEditing(cameraID) {
    var newEditingNames = { ...editingCameraNames };
    newEditingNames[cameraID].editing = false;
    setEditingCameraNames(newEditingNames);
  }

  function handleNamingInput(e) {
    console.log(e.target.value);
    console.log(e.target.id);
    const cameraID = e.target.id;
    const newName = e.target.value;
    //TODO We need to sync to a placeholder name on input
    var newEditingNames = { ...editingCameraNames };
    newEditingNames[cameraID].newName = newName;
    setEditingCameraNames(newEditingNames);
  }
  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <Container>
          <Row className="p-5">
            <Col>
              <h4> Managing Sensors for {networkID}</h4>
            </Col>
            <Col>
              <Button
                className="float-right mr-2"
                href={`${NETWORKS}/${networkID}`}
              >
                Done
              </Button>
              <Button
                className="float-right mr-2"
                variant="secondary"
                href={`${NETWORKS}/${networkID}/new-floorplan`}
              >
                Upload New Floorplan
              </Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <LayoutImageView
                networkID={networkID}
              ></LayoutImageView>
            </Col>
            <Col md={3}>
              {loading ? (
                <p>Loading</p>
              ) : (
                <div>
                  {camerasList && camerasList.length > 0 ? (
                    <div>
                      <ListGroup>
                        {' '}
                        {camerasList.map((item) => (
                          <ListGroup.Item key={item.id}>
                            <Row>
                              <Col>
                                <HeatMapMarker
                                  networkID={networkID}
                                  cameraID={item.id}
                                  isSet={item.location != null}
                                />
                              </Col>
                              <Col>
                                <div className="align-items-center">
                                  <Row>
                                    {editingCameraNames[item.id]
                                      .editing ? (
                                      <InputGroup
                                        onChange={handleNamingInput}
                                        className="mb-3"
                                      >
                                        <FormControl
                                          id={item.id}
                                          placeholder={item.name}
                                          aria-label="Network Name"
                                        />
                                      </InputGroup>
                                    ) : (
                                      <div>{item.name}</div>
                                    )}
                                  </Row>
                                  <Row>
                                    {editingCameraNames[item.id]
                                      .editing ? (
                                      <div>
                                        <XCircleFill
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                          color="red"
                                          className="float-right mr-3"
                                          onClick={() => {
                                            cancelEditing(item.id);
                                          }}
                                        ></XCircleFill>
                                        <CheckCircleFill
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                          color="green"
                                          className="float-right mr-3"
                                          onClick={() => {
                                            saveEditing(item.id);
                                          }}
                                        ></CheckCircleFill>
                                      </div>
                                    ) : (
                                      <div>
                                        {item.location != null && (
                                          <Trash
                                            color="red"
                                            style={{
                                              cursor: 'pointer',
                                            }}
                                            className="float-right m-3"
                                            onClick={() => {
                                              removeMarker(item.id);
                                            }}
                                          ></Trash>
                                        )}
                                        <PencilSquare
                                          color="green"
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                          className="float-right m-3"
                                          onClick={() => {
                                            startEditing(item.id);
                                          }}
                                        ></PencilSquare>
                                      </div>
                                    )}
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ))}{' '}
                      </ListGroup>
                      <i className="d-flex justify-content-center">
                        Drag to place
                      </i>
                    </div>
                  ) : (
                    <i className="d-flex justify-content-center">
                      No sensors
                    </i>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </DndProvider>
    </div>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withAuthentication,
  withAuthorization(condition),
)(HeatmapEditPage);
