import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import ImagesPage from '../Images';
import PasswordForgetPage from '../PasswordForget';
import UploadFloorplanPage from '../HeatmapPage/UploadFloorplanPage';
import NetworkAdminPage from '../Admin/NetworkAdmin';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import HeatmapPage from '../HeatmapPage';
import HeatmapEditPage from '../HeatmapEditPage';
import AdminHomePage from '../Admin';

const App = () => (
  <Router>
    <div>
      <Navigation />

      <hr />

      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route exact path={ROUTES.NETWORKS} component={LandingPage} />
      <Route
        exact
        path={ROUTES.NETWORK_HEATMAP_EDIT}
        component={HeatmapEditPage}
      />
      <Route
        exact
        path={ROUTES.NETWORK_HEATMAP_FLOORPLAN}
        component={UploadFloorplanPage}
      />
      <Route
        exact
        path={ROUTES.NETWORK_HEATMAP}
        component={HeatmapPage}
      />
      <Route exact path={ROUTES.IMAGES} component={ImagesPage} />
      <Route exact path={ROUTES.ADMIN} component={AdminHomePage} />
      <Route
        exact
        path={ROUTES.NETWORK_ADMIN}
        component={NetworkAdminPage}
      />
      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route
        path={ROUTES.PASSWORD_FORGET}
        component={PasswordForgetPage}
      />
    </div>
  </Router>
);

export default withAuthentication(App);
