import React from 'react';
import { GeoAltFill, GeoAlt } from 'react-bootstrap-icons';
import { useDrag } from 'react-dnd';
import { ItemTypes } from '../Constants';

const HeatMapMarker = (props) => {
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ItemTypes.SENSOR_LOCATION,
      networkID: props.networkID,
      cameraID: props.cameraID,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  return (
    <div>
      <div
        class="mr-3"
        ref={drag}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: 'move',
        }}
      >
        {props.isSet ? (
          <GeoAltFill size={36} style={props.markerStyle} />
        ) : (
          <GeoAlt size={36} />
        )}
      </div>
    </div>
  );
};

export default HeatMapMarker;
