import React, { useEffect, useState } from 'react';
import h337 from 'heatmap.js';
import { Form } from 'react-bootstrap';

const aggregateData = (detectionList) => {
  var labels = ['all'];
  //console.log(labels);
  detectionList.forEach((element) => {
    if (!labels.includes(element.label)) {
      labels.push(element.label);
    }
  });
  //console.log(typeof labels);
  var allData = {
    labels: labels,
  };
  var totalMaxCount = 0;
  var totalAggregate = {};

  labels.forEach((label) => {
    // Process each label and get aggregation of it
    var aggregate = {};
    var maxCount = 0;

    detectionList.forEach((element) => {
      // //console.log(element)
      if (element.label === label) {
        let locationIndex = `${element.location.normalizedX}|${element.location.normalizedY}`;
        if (locationIndex in aggregate) {
          // Already exists, increment count
          // Check if max
          const aggregateLocationData = aggregate[locationIndex];
          aggregateLocationData.count =
            aggregateLocationData.count + 1;
          aggregate[locationIndex] = aggregateLocationData;
          maxCount = Math.max(aggregateLocationData.count, maxCount);
        } else {
          aggregate[locationIndex] = {
            location: element.location,
            label: element.label,
            count: 1,
          };
          maxCount = Math.max(1, maxCount);
        }
        if (locationIndex in totalAggregate) {
          // Already exists, increment count
          // Check if max
          const aggregateLocationData = totalAggregate[locationIndex];
          aggregateLocationData.count =
            aggregateLocationData.count + 1;
          totalAggregate[locationIndex] = aggregateLocationData;
          totalMaxCount = Math.max(
            aggregateLocationData.count,
            totalMaxCount,
          );
        } else {
          totalAggregate[locationIndex] = {
            location: element.location,
            label: element.label,
            count: 1,
          };
          totalMaxCount = Math.max(1, totalMaxCount);
        }
      }
    });
    var results = [];
    for (const property in aggregate) {
      results.push(aggregate[property]);
    }
    allData[label] = {
      maxCount: maxCount,
      results: results,
    };
  });
  var totalResults = [];
  for (const property in totalAggregate) {
    totalResults.push(totalAggregate[property]);
  }
  allData['all'] = {
    maxCount: totalMaxCount,
    results: totalResults,
  };
  //console.log(allData);
  return allData;
};

var heatmapInstance;
const Heatmap = (props) => {
  const [labels, setLabels] = useState([]);
  const [imgDims, setImgDims] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chosenLabel, setChosenLabel] = useState('');
  const [aggregatedHeatmapData, setAggregatedHeatmapData] = useState(
    {},
  );

  const handleSelectChange = (e) => {
    setChosenLabel(e.target.value);
  };
  const generateHeatmap = (label) => {
    var points = [];
    var width = imgDims.width;
    var height = imgDims.height;
    const detectionData = aggregatedHeatmapData[label];
    //console.log(detectionData)
    detectionData.results.forEach((detection) => {
      var point = {
        x: Math.floor(detection.location.normalizedX * width),
        y: Math.floor(detection.location.normalizedY * height),
        value: detection.count,
      };
      points.push(point);
    });

    // heatmap data format
    var data = {
      max: detectionData.maxCount,
      data: points,
    };
    //console.log(heatmapInstance);
    if (!heatmapInstance) {
      heatmapInstance = h337.create({
        // only container is required, the rest will be defaults
        container: document.querySelector('.Heatmap'),
      });
    }
    // if you have a set of datapoints always use setData instead of addData
    // for data initialization
    heatmapInstance.setData(data);
  };
  useEffect(() => {
    if (chosenLabel && imgDims) {
      generateHeatmap(chosenLabel);
    }
  }, [aggregatedHeatmapData, chosenLabel, imgDims]);
  useEffect(() => {
    var img = new Image();
    img.src = props.imageB64;
    img.onload = function () {
      //console.log(img.width);   // This will print out the width.
      setImgDims({ height: img.height, width: img.width });
    };
    const aggregatedData = aggregateData(props.heatmapData);
    setLabels(aggregatedData.labels);

    setAggregatedHeatmapData(aggregatedData);
    if (aggregatedData.labels.length > 0) {
      setChosenLabel(aggregatedData.labels[0]);
    }
    setLoading(false);

    // now generate some random data
    //TODO: Fill this with the points from the data
  }, []);
  return (
    <div>
      {/* <h1> RaptAIr Heatmap</h1> */}
      {!loading && imgDims && (
        <div>
          <Form>
            <Form.Group controlId="heatmap.LabelSelect">
              <Form.Label>Choose Category for Heatmap</Form.Label>
              <Form.Control onChange={handleSelectChange} as="select">
                {labels.map((label) => (
                  <option key={label}>{label}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
          <div
            className="Heatmap"
            style={{
              backgroundImage: 'url(' + props.imageB64 + ')',
              //backgroundPosition: 'center',
              //backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: imgDims.height,
              width: imgDims.width,
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default Heatmap;
