import React, { useEffect, useState, useContext } from 'react';
import {
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Button,
} from 'react-bootstrap';
import { FirebaseContext } from '../Firebase';
import { withAuthorization, withAuthentication } from '../Session';
import { compose } from 'recompose';
import ImageCard from './ImageCard';
import { InfiniteScroll } from 'libreact/lib/InfiniteScroll';

const ImagesPage = (routeProp) => {
  const [userImages, setUserImages] = useState([]);
  const [labelFilterOptions, setLabelFilterOptions] = useState({});
  const [cameraFilterOptions, setCameraFilterOptions] = useState({});
  const [currentUserImages, setCurrentUserImages] = useState([]);
  const [selectState, setSelectState] = useState(false);
  const firebase = useContext(FirebaseContext);
  const INFINITE_SCROLL_INCREMENT = 15;
  function authStateChangedCallback(user) {
    if (user) {
      // User is signed in.

      firebase.images().on('value', (snapshot) => {
        const images = snapshot.val();
        //TODO: We need to convert these keys into a list to consume in the UI.

        const imagesList = Object.keys(images).map((key) => {
          var img = images[key];
          img['key'] = key;
          return img;
        });
        console.log(imagesList);
        const fullList = imagesList.reverse().slice(0, 50);
        setUserImages(fullList);
        setCurrentUserImages(
          fullList.slice(0, INFINITE_SCROLL_INCREMENT),
        );
      });
    } else {
      // No user is signed in.
      console.log('No luck :(');
    }
  }
  function loadMore() {
    if (currentUserImages.length === userImages.length) {
      return;
    }
    const newIndex = Math.min(
      userImages.length - 1,
      currentUserImages.length + INFINITE_SCROLL_INCREMENT,
    );
    setCurrentUserImages(userImages.slice(0, newIndex));
  }
  useEffect(() => {
    //TODO: Make this dynamic
    firebase.registerAuthListener(authStateChangedCallback);
  }, []);

  // Only run when userImages changes
  useEffect(() => {
    var labels = {};
    var cameras = {};
    userImages.forEach((img) => {
      labels[img.label] = true;
      cameras[img.cameraId] = true;
    });
    setLabelFilterOptions(labels);
    setCameraFilterOptions(cameras);
  }, [userImages]);
  function onLabelFilterChange(event) {
    const newState = event.target.checked;
    const label = event.target.id;
    var newLabelState = { ...labelFilterOptions };
    newLabelState[label] = newState;
    setLabelFilterOptions(newLabelState);
  }
  function onCameraFilterChange(event) {
    const newState = event.target.checked;
    const camera = event.target.id;
    var newCameraState = { ...cameraFilterOptions };
    newCameraState[camera] = newState;
    setCameraFilterOptions(newCameraState);
  }
  const selectClick = () => {
    if (selectState) {
      var newImageList = [];
      userImages.forEach((imgRecord) => {
        imgRecord.selected = false;
        newImageList.push(imgRecord);
      });
      setUserImages(newImageList);
      setSelectState(false);
    } else {
      setSelectState(true);
    }
  };

  const deleteSelected = () => {
    console.log('Deleting!');
    var deletionList = [];
    var newImageList = [];
    userImages.forEach((imgRecord) => {
      // console.log(imgRecord);
      if (imgRecord.selected) {
        deletionList.push(imgRecord);
      } else {
        newImageList.push(imgRecord);
      }
    });
    console.log(deletionList);
    deletionList.forEach((imgRecord) => {
      console.log(`Deleting image: ${imgRecord.key}`);
      firebase.deleteImage(imgRecord.key);
    });
    setUserImages(newImageList);
    setSelectState(false);
  };
  const onImageClick = (image) => {
    if (selectState) {
      var newImageList = [];
      userImages.forEach((imgRecord) => {
        if (imgRecord.imageId === image.imageId) {
          if (imgRecord.selected !== undefined) {
            imgRecord.selected = !imgRecord.selected;
          } else {
            imgRecord.selected = true;
          }
        }
        newImageList.push(imgRecord);
      });
      setUserImages(newImageList);
    }
  };
  return (
    <Container>
      <Row>
        <h1 className="mr-auto">Images</h1>
        {selectState ? (
          <div>
            <div className="mx-2 float-right">
              <Button
                className="p-2"
                onClick={selectClick}
                variant="secondary"
              >
                Cancel
              </Button>
            </div>
            <div className="mx-2 float-right">
              <Button
                onClick={deleteSelected}
                className="p-2"
                variant="danger"
              >
                Delete
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className="mx-2 float-right">
              <Button onClick={selectClick} variant="secondary">
                Select
              </Button>
            </div>
            <div className="mx-2 float-right">
              <Dropdown>
                <Dropdown.Toggle variant="info" id="dropdown-basic">
                  Filter Cameras
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Form className="p-2">
                    {Object.keys(cameraFilterOptions).map(
                      (keyName) => {
                        return (
                          <div
                            key={`checkbox-${keyName}`}
                            className="mb-3"
                          >
                            <Form.Check
                              type="checkbox"
                              id={`${keyName}`}
                              label={`${keyName}`}
                              onChange={onCameraFilterChange}
                              checked={cameraFilterOptions[keyName]}
                            />
                          </div>
                        );
                      },
                    )}
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="mx-2 float-right">
              <Dropdown>
                <Dropdown.Toggle
                  variant="primary"
                  id="dropdown-basic"
                >
                  Filter Labels
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Form className="p-2">
                    {Object.keys(labelFilterOptions).map(
                      (keyName) => {
                        return (
                          <div
                            key={`checkbox-${keyName}`}
                            className="mb-3"
                          >
                            <Form.Check
                              type="checkbox"
                              id={`${keyName}`}
                              label={`${keyName}`}
                              onChange={onLabelFilterChange}
                              checked={labelFilterOptions[keyName]}
                            />
                          </div>
                        );
                      },
                    )}
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        )}
      </Row>
      <Row>
        <InfiniteScroll
          hasMore={currentUserImages.length !== userImages.length}
          cursor={currentUserImages.length}
          loadMore={loadMore}
        >
          {currentUserImages.map((image) => {
            if (
              labelFilterOptions[image.label] &&
              cameraFilterOptions[image.cameraId]
            ) {
              return (
                <Col key={image.imageId} sm={4}>
                  <ImageCard
                    onClick={() => {
                      console.log('Clicked image card');
                      onImageClick(image);
                    }}
                    image={image}
                    selected={image.selected}
                  ></ImageCard>
                </Col>
              );
            }
            return null;
          })}
        </InfiniteScroll>
      </Row>
    </Container>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withAuthentication,
  withAuthorization(condition),
)(ImagesPage);
