import React, { useContext, useEffect, useState } from 'react';

import {
  Button,
  Col,
  Container,
  FormControl,
  InputGroup,
  ListGroup,
  Row,
} from 'react-bootstrap';
import { FirebaseContext } from '../../Firebase';
import QRCode from 'qrcode.react';
import { Trash } from 'react-bootstrap-icons';
import { ADMIN } from '../../../constants/routes';

const DeleteCameraButton = (props) => {
  const networkID = props.networkID;
  const cameraID = props.cameraID;
  const [areYouSure, setAreYouSure] = useState(false);
  const deletionCallback = props.onDelete;
  return (
    <div className="float-right">
      {areYouSure ? (
        <Button
          variant="danger"
          onClick={() => {
            deletionCallback(networkID, cameraID);
            setAreYouSure(false);
          }}
          className="float-right"
        >
          Confirm Delete
        </Button>
      ) : (
        <Trash
          onClick={() => {
            setAreYouSure(true);
          }}
          className="float-right"
        ></Trash>
      )}
    </div>
  );
};

const NetworkAdminPage = (routeProp) => {
  const [enabled, setEnable] = useState(true);
  const [loading, setLoading] = useState(true);
  const [firstClick, setFirstClick] = useState(false);
  const [sensorsList, setSensorsList] = useState([]);

  const enableNetwork = () => {
    // Update network on firebase
    firebase.setNetworkState(networkId, true);
    setEnable(true);
  };

  const disableNetwork = () => {
    firebase.setNetworkState(networkId, false);
    setEnable(false);
  };

  const [isRenaming, setIsRenaming] = useState(false);
  const [networkName, setNetworkName] = useState(
    routeProp.match.params.networkId,
  );
  const [newNetworkName, setNewNetworkName] = useState(
    routeProp.match.params.networkId,
  );
  const firebase = useContext(FirebaseContext);

  const deleteSensor = (networkID, sensorID) => {
    firebase.deleteNetworkCamera(networkID, sensorID);
    var newCameras = [];
    sensorsList.forEach((sensor) => {
      if (sensor.id !== sensorID) {
        newCameras.push(sensor);
      }
    });
    setSensorsList(newCameras);
  };

  //   let { networkId } = useParams();
  //console.log(routeProp);
  const networkId = routeProp.match.params.networkId;
  useEffect(() => {
    firebase.networkName(networkId).on('value', (snapshot) => {
      const netName = snapshot.val();
      if (netName) {
        setNetworkName(netName);
      }
    });
    firebase.networkEnabled(networkId).on('value', (snapshot) => {
      const enabled = snapshot.val();
      if (enabled === null) {
        // firebase.setNetworkState(networkId, true);
        setEnable(true);
      } else {
        setEnable(enabled);
      }
    });
    firebase.networkCameras(networkId).on('value', (snapshot) => {
      const cameras = snapshot.val();
      if (cameras !== null) {
        const newCamerasList = Object.keys(cameras).map((key) => ({
          ...cameras[key],
          uid: key,
        }));
        setSensorsList(newCamerasList);
      }

      setLoading(false);
    });
  }, []);

  function toggleRename() {
    if (isRenaming) {
      // We need to save if the state has changed...
      setIsRenaming(false);
      if (newNetworkName !== networkName) {
        setNetworkName(newNetworkName);
        //TODO: Send to firebase too
        firebase.setNetworkName(networkId, newNetworkName);
      }
    } else {
      setIsRenaming(true);
    }
  }
  function cancelRename() {
    setIsRenaming(false);
  }
  function handleNamingInput(e) {
    setNewNetworkName(e.target.value);
  }
  function deleteNetwork() {
    if (firstClick) {
      firebase.deleteNetwork(networkId);
      routeProp.history.push(ADMIN);
    } else {
      setFirstClick(true);
    }
  }
  const qrPayload = {
    networkId: networkId,
    token: '',
  };
  return (
    <div>
      <Container>
        <Row>
          <Col>
            {isRenaming ? (
              <h4>
                <InputGroup
                  onChange={handleNamingInput}
                  className="mb-3"
                >
                  <FormControl
                    placeholder={networkName}
                    aria-label="Network Name"
                  />
                </InputGroup>
              </h4>
            ) : (
              <h4>{networkName}</h4>
            )}
            <i>ID: {routeProp.match.params.networkId}</i>
            <br></br>
            <i>{enabled ? 'Enabled' : 'Disabled'} </i>
            <br></br>
          </Col>

          <Col className="mr-2">
            {isRenaming ? (
              <div>
                <Button
                  className="float-right mr-2 btn-danger"
                  onClick={cancelRename}
                >
                  Cancel
                </Button>
                <Button
                  className="float-right mr-2 btn-success"
                  onClick={toggleRename}
                >
                  Save Name
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  className="float-right mr-2"
                  variant="danger"
                  onClick={deleteNetwork}
                >
                  {firstClick
                    ? "Are you sure? This can't be undone."
                    : 'Delete Network'}
                </Button>
                <Button
                  className="float-right mr-2 btn-secondary"
                  onClick={toggleRename}
                >
                  Rename Network
                </Button>
                {enabled ? (
                  <Button
                    className="float-right mr-2"
                    variant="warning"
                    onClick={disableNetwork}
                  >
                    Disable Network
                  </Button>
                ) : (
                  <Button
                    className="float-right mr-2"
                    variant="success"
                    onClick={enableNetwork}
                  >
                    Enable Network
                  </Button>
                )}
              </div>
            )}
          </Col>
        </Row>
        <Row className="pt-5">
          <Col>
            {loading ? (
              <p> Loading sensors </p>
            ) : (
              <ListGroup>
                {sensorsList.map((item) => (
                  <ListGroup.Item key={item.id}>
                    {item.name || item.id}
                    <DeleteCameraButton
                      onDelete={deleteSensor}
                      networkID={networkId}
                      cameraID={item.id}
                    ></DeleteCameraButton>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </Col>
          <Col>
            <Row>
              <QRCode size={256} value={JSON.stringify(qrPayload)} />
            </Row>
            <Row>Scan with user's app to activate user</Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NetworkAdminPage;
