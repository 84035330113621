import React, { useEffect, useState, useContext } from 'react';
import {
  Alert,
  Button,
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { FirebaseContext } from '../Firebase';
import { withAuthorization, withAuthentication } from '../Session';
import { compose } from 'recompose';

import Heatmap from '../Heatmap';

const HeatmapPage = (routeProp) => {
  const [heatmapDataState, setHeatmapData] = useState(null);
  const [isRenaming, setIsRenaming] = useState(false);
  const [networkName, setNetworkName] = useState(
    routeProp.match.params.networkId,
  );
  const [newNetworkName, setNewNetworkName] = useState(
    routeProp.match.params.networkId,
  );
  const [floorplanImg, setFloorplanImg] = useState(null);
  const firebase = useContext(FirebaseContext);
  //   let { networkId } = useParams();
  //console.log(routeProp);
  const networkId = routeProp.match.params.networkId;
  useEffect(() => {
    //TODO: Make this dynamic
    firebase.heatmapData(networkId).on('value', (snapshot) => {
      const heatmapData = snapshot.val();
      //console.log(heatmapData);
      if (heatmapData === null) {
        setHeatmapData([]);
        return;
      }
      const newHeatmapData = Object.keys(heatmapData).map((key) => ({
        ...heatmapData[key],
        key: key,
      }));
      //console.log("Heatmap Data");
      //console.log(newHeatmapData);
      setHeatmapData(newHeatmapData);
    });
    firebase.networkName(networkId).on('value', (snapshot) => {
      const netName = snapshot.val();
      if (netName) {
        setNetworkName(netName);
      }
    });
  }, []);
  const getFloorplan = () => {
    firebase.floorplan(networkId).on('value', (snapshot) => {
      const floorplan = snapshot.val();
      //console.log(floorplan);
      if (floorplan === null) {
        setFloorplanImg(null);
        return;
      }
      setFloorplanImg(floorplan.b64);
    });
  };
  function toggleRename() {
    if (isRenaming) {
      // We need to save if the state has changed...
      setIsRenaming(false);
      if (newNetworkName !== networkName) {
        setNetworkName(newNetworkName);
        //TODO: Send to firebase too
        firebase.setNetworkName(networkId, newNetworkName);
      }
    } else {
      setIsRenaming(true);
    }
  }
  function cancelRename() {
    setIsRenaming(false);
  }
  function handleNamingInput(e) {
    setNewNetworkName(e.target.value);
  }
  useEffect(() => {
    getFloorplan();
  }, []);
  return (
    <div>
      <Container>
        <Row>
          <Col>
            {isRenaming ? (
              <h4>
                <InputGroup
                  onChange={handleNamingInput}
                  className="mb-3"
                >
                  <FormControl
                    placeholder={networkName}
                    aria-label="Network Name"
                  />
                </InputGroup>
              </h4>
            ) : (
              <h4>{networkName}</h4>
            )}
            <i>ID: {routeProp.match.params.networkId}</i>
            <br></br>
          </Col>
          <Col className="mr-2">
            {isRenaming ? (
              <div>
                <Button
                  className="float-right mr-2 btn-danger"
                  onClick={cancelRename}
                >
                  Cancel
                </Button>
                <Button
                  className="float-right mr-2 btn-success"
                  onClick={toggleRename}
                >
                  Save Name
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  className="float-right mr-2 btn-secondary"
                  onClick={toggleRename}
                >
                  Rename Network
                </Button>
                <Button
                  className="float-right mr-2"
                  href={`./${networkId}/edit`}
                >
                  Edit Map
                </Button>
              </div>
            )}
          </Col>
        </Row>

        {heatmapDataState && floorplanImg ? (
          <Heatmap
            heatmapData={heatmapDataState}
            imageB64={floorplanImg}
          ></Heatmap>
        ) : (
          <div>
            {floorplanImg === null ? (
              <Alert variant="warning">
                No floorplan uploaded. Click edit map to upload one.
              </Alert>
            ) : (
              <div>Loading heatmap</div>
            )}
          </div>
        )}
      </Container>
    </div>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withAuthentication,
  withAuthorization(condition),
)(HeatmapPage);
