import React from 'react';

import { AuthUserContext } from '../Session';
// import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
// import * as ROLES from '../../constants/roles';
import { Nav, Navbar } from 'react-bootstrap';
import SignOut from '../SignOut';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
  <Navbar>
    <Navbar.Brand href="/">RaptAIr Map Manager</Navbar.Brand>
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-2">
        <Nav.Link href={ROUTES.IMAGES}>Images</Nav.Link>
      </Nav>
      <Nav className="mr-auto">
        <Nav.Link href={ROUTES.NETWORKS}>Networks</Nav.Link>
      </Nav>
      <Nav className="mr-2 float-right">
        {/* <Nav.Link to={ROUTES.ACCOUNT}>Sign Out</Nav.Link> */}
        <SignOut></SignOut>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

const NavigationNonAuth = () => (
  <Navbar>
    <Navbar.Brand href="/">RaptAIr Map Manager</Navbar.Brand>
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link to={ROUTES.SIGN_IN}>Sign In</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default Navigation;
