import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

var config = {
  apiKey: 'AIzaSyBuyQVjIPPPa-airIIzMxajjb8CMyJwCEY',
  authDomain: 'raptair-pest-detector-60ea4.firebaseapp.com',
  databaseURL: 'https://raptair-pest-detector-60ea4.firebaseio.com',
  projectId: 'raptair-pest-detector-60ea4',
  storageBucket: 'raptair-pest-detector-60ea4.appspot.com',
  messagingSenderId: '272484376998',
  appId: '1:272484376998:web:3601d245ed6f31afc6af67',
  measurementId: 'G-6LE6823BGD',
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();

    /* Social Sign In Method Provider */

    // this.googleProvider = new app.auth.GoogleAuthProvider();
    // this.facebookProvider = new app.auth.FacebookAuthProvider();
    // this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) =>
    this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      console.log('OnAuthStateChanged');
      console.log(authUser);
      if (authUser) {
        this.currentUser = authUser.uid;

        this.user(authUser.uid)
          .once('value')
          .then((snapshot) => {
            const dbUser = snapshot.val();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        // No user is signed in.
        this.currentUser = null;
        fallback();
      }
    });

  registerAuthListener = (callback) => {
    this.auth.onAuthStateChanged(callback);
  };

  // *** User API ***

  user = (uid) => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  getUser = () => this.auth.currentUser;

  // *** Message API ***

  message = (uid) => this.db.ref(`messages/${uid}`);

  messages = () => this.db.ref('messages');
  // Networks API
  networks = (uid) => this.db.ref(`users/${uid}/networks`);

  networkCameras = (networkId) =>
    this.db.ref(`networks/${networkId}/cameras`);

  deleteNetworkCamera = (networkID, sensorID) => {
    this.db.ref(`networks/${networkID}/cameras/${sensorID}`).remove();
  };

  images = () => {
    console.log('Firebase images call');
    console.log(this.auth.currentUser.uid);
    return this.db.ref(`images/${this.auth.currentUser.uid}`);
  };

  deleteImage = (photoID) => {
    this.db
      .ref(`images/${this.auth.currentUser.uid}/${photoID}`)
      .remove();
  };

  networkName = (networkId) =>
    this.db.ref(`networks/${networkId}/name`);

  setNetworkName = (networkID, newNetworkName) => {
    this.db.ref(`networks/${networkID}/name`).set(newNetworkName);
  };

  networkLayout = (networkId) =>
    this.db.ref(`networks/${networkId}/layout`);

  setCameraLocation = (
    networkID,
    cameraID,
    normalizedX,
    normalizedY,
  ) => {
    // Get the camera
    // Set the normalized number
    this.db
      .ref(`networks/${networkID}/cameras/${cameraID}/location`)
      .set({
        normalizedX: normalizedX,
        normalizedY: normalizedY,
      });
  };

  unsetCameraLocation = (networkID, cameraID) => {
    // Get the camera
    // Set the normalized number
    this.db
      .ref(`networks/${networkID}/cameras/${cameraID}/location`)
      .remove();
  };

  setCameraName = (networkID, cameraID, name) => {
    // Get the camera
    // Set the normalized number
    this.db
      .ref(`networks/${networkID}/cameras/${cameraID}/name`)
      .set(name);
  };

  setFloorplan = (networkID, floorplanData) => {
    // Delete existing
    this.db.ref(`networks/${networkID}/floorplan`).set({
      b64: floorplanData,
    });
    this.db.ref(`networks/${networkID}/heatmap-log`).remove();
  };

  floorplan = (networkID) => {
    return this.db.ref(`networks/${networkID}/floorplan`);
  };

  heatmapData = (networkId) =>
    this.db.ref(`networks/${networkId}/heatmap-log`);

  // Admin API
  allNetworks = () => {
    return this.db.ref(`networks`);
  };

  networkEnabled = (networkID) => {
    return this.db.ref(`networks/${networkID}/enabled`);
  };

  deleteNetwork = (networkID) => {
    this.db.ref(`networks/${networkID}`).remove();
  };

  setNetworkState = (networkID, enabled) => {
    this.db.ref(`networks/${networkID}/enabled`).set(enabled);
  };
}

export default Firebase;
