import React, { useContext, useEffect, useState } from 'react';

import { Col, Container, Row, Table } from 'react-bootstrap';
import { FirebaseContext } from '../Firebase';
import { ADMIN, NETWORKS } from '../../constants/routes';
import { EyeFill } from 'react-bootstrap-icons';

const AdminHomePage = () => {
  const [loading, setLoading] = useState(true);
  const [networksList, setNetworksList] = useState([]);
  const [numNetworks, setNumNetworks] = useState(-1);
  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    //TODO: Make this dynamic
    firebase.allNetworks().on('value', (snapshot) => {
      const networksObject = snapshot.val();
      console.log(networksObject);
      const tempNetworkList = Object.keys(networksObject).map(
        (key) => ({
          ...networksObject[key],
          uid: key,
        }),
      );
      setNumNetworks(tempNetworkList.length);
      tempNetworkList.forEach((network) => {
        firebase
          .networkName(network.id)
          .get()
          .then((snapshot) => {
            const netName = snapshot.val();
            network.networkName = netName;
            setNetworksList((oldList) => [...oldList, network]);
            if (tempNetworkList.length === networksList.length) {
              setLoading(false);
            }
          });
      });
    });
    //console.log("useEffect");
  }, []);
  useEffect(() => {
    if (numNetworks === networksList.length) {
      setLoading(false);
    }
  }, [networksList]);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h1>Network Admin</h1>
            {loading ? (
              <p> Loading networks. </p>
            ) : (
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>ID</th>
                    <th>Heatmap</th>
                  </tr>
                </thead>
                <tbody>
                  {networksList.map((item) => (
                    <tr key={item.uid}>
                      <td>
                        <a href={`${ADMIN}/networks/${item.uid}`}>
                          {item.name || item.uid}
                        </a>
                      </td>
                      <td>{item.uid}</td>
                      <td>
                        <a href={`${NETWORKS}/${item.uid}`}>
                          <EyeFill></EyeFill>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              // <ListGroup>
              //   {networksList.map((item) => (
              //     <ListGroup.Item key={item.uid}>
              //       <a href={`${ADMIN}/networks/${item.uid}`}>
              //         {item.name || item.uid}
              //       </a>
              //       {item.name !== item.uid && (
              //         <i text="secondary"> ({item.uid}) </i>
              //       )}
              //       <a href={`${NETWORKS}/${item.uid}`}>
              //         <EyeFill className="float-right"></EyeFill>
              //       </a>
              //     </ListGroup.Item>
              //   ))}
              // </ListGroup>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminHomePage;
