import React from 'react';
import { Card } from 'react-bootstrap';

const ImageCard = (props) => {
  return (
    <div className="py-2">
      <Card
        text={props.selected ? 'white' : 'dark'}
        onClick={props.onClick}
        bg={props.selected ? 'dark' : ''}
      >
        <Card.Img variant="top" src={props.image.url} />
        <Card.Body>
          <Card.Title>{props.image.label}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{`${props.image.cameraId}`}</Card.Subtitle>
          <Card.Subtitle className="mb-2 text-muted">{`${props.image.networkId}`}</Card.Subtitle>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ImageCard;
