export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const NETWORKS = '/networks';
export const IMAGES = '/images';
export const NETWORK_HEATMAP = '/networks/:networkId';
export const NETWORK_HEATMAP_EDIT = '/networks/:networkId/edit';
export const NETWORK_HEATMAP_FLOORPLAN =
  '/networks/:networkId/new-floorplan';
export const ADMIN = '/admin';
export const NETWORK_ADMIN = '/admin/networks/:networkId';
