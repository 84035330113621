import React, { useEffect, useState, useContext } from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import { NETWORKS } from '../../constants/routes';
import { FirebaseContext } from '../Firebase';
import { compose } from 'recompose';

import { withAuthentication, withAuthorization } from '../Session';

const NetworksLanding = (props) => {
  const [loading, setLoading] = useState(true);
  const [networksList, setNetworksList] = useState([]);
  const [numNetworks, setNumNetworks] = useState(-1);
  const firebase = useContext(FirebaseContext);
  function authStateChangedCallback(user) {
    if (user) {
      // User is signed in.
      console.log('Woohoo we got a callback');
      console.log(user);
      const uid = user.uid;
      firebase.networks(uid).on('value', (snapshot) => {
        const networksObject = snapshot.val();
        console.log(networksObject);
        const tempNetworkList = Object.keys(networksObject).map(
          (key) => ({
            ...networksObject[key],
            uid: key,
          }),
        );
        setNumNetworks(tempNetworkList.length);
        tempNetworkList.forEach((network) => {
          firebase
            .networkName(network.id)
            .get()
            .then((snapshot) => {
              const netName = snapshot.val();
              network.networkName = netName;
              setNetworksList((oldList) => [...oldList, network]);
              if (tempNetworkList.length === networksList.length) {
                setLoading(false);
              }
            });
        });
      });
    } else {
      // No user is signed in.
      console.log('No luck :(');
    }
  }
  useEffect(() => {
    //TODO: Make this dynamic
    firebase.registerAuthListener(authStateChangedCallback);
    return;
    //console.log("useEffect");
  }, []);
  useEffect(() => {
    if (numNetworks === networksList.length) {
      setLoading(false);
    }
  }, [networksList]);
  return (
    <div>
      <Container>
        <h1> Networks </h1>
        {loading ? (
          <p> Loading networks. </p>
        ) : (
          <ListGroup>
            {networksList.map((item) => (
              <a href={`${NETWORKS}/${item.id}`}>
                <ListGroup.Item key={item.id}>
                  {item.networkName || item.id}
                </ListGroup.Item>
              </a>
            ))}
          </ListGroup>
        )}
      </Container>
    </div>
  );
};
const condition = (authUser) => !!authUser;

export default compose(
  withAuthentication,
  withAuthorization(condition),
)(NetworksLanding);
