import React, { useState, useContext, useEffect } from 'react';
import {
  Alert,
  Image,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import { useDrop } from 'react-dnd';
import { ItemTypes } from '../Constants';
import { FirebaseContext } from '../../Firebase';
import HeatMapMarker from '../Marker';

function mapMarkerTooltip(props) {
  let sensorName = '';
  if (props.popper.state) {
    sensorName = props.popper.state.options.sensorName;
  }
  return (
    <Tooltip id="button-tooltip" {...props}>
      {sensorName}
    </Tooltip>
  );
}

const LayoutImageView = (props) => {
  const [showSensors, setShowSensors] = useState(false);
  const [sensors, setSensors] = useState([]);
  const [dimensions, setDimensions] = useState({});
  const [floorplanImg, setFloorplanImg] = useState(null);
  const firebase = useContext(FirebaseContext);
  const [, drop] = useDrop({
    accept: ItemTypes.SENSOR_LOCATION,
    drop: (item, monitor) => {
      const clientOffset = monitor.getClientOffset();
      const domRect = document
        .getElementById('floorplan-img')
        .getBoundingClientRect();
      const xOffset = domRect.x;
      const yOffset = domRect.y;
      const trueDropLocation = {
        x: clientOffset.x - xOffset,
        y: clientOffset.y - yOffset,
      };
      const normalizedDropLocation = {
        x: trueDropLocation.x / dimensions.width,
        y: trueDropLocation.y / dimensions.height,
      };
      //console.log(normalizedDropLocation)
      const sensor = monitor.getItem();
      firebase.setCameraLocation(
        sensor.networkID,
        sensor.cameraID,
        normalizedDropLocation.x,
        normalizedDropLocation.y,
      );
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const getCameras = () => {
    firebase
      .networkCameras(props.networkID)
      .on('value', (snapshot) => {
        const networkCameras = snapshot.val();
        if (networkCameras === null) {
          setSensors([]);
          setShowSensors(true);
          return;
        }
        const newCamerasList = Object.keys(networkCameras).map(
          (key) => ({
            ...networkCameras[key],
          }),
        );
        setSensors(newCamerasList);
        setShowSensors(true);
      });
  };

  const getFloorplan = () => {
    firebase.floorplan(props.networkID).on('value', (snapshot) => {
      const floorplan = snapshot.val();
      //console.log(floorplan);
      if (floorplan === null) {
        setFloorplanImg(null);
        return;
      }
      setFloorplanImg(floorplan.b64);
    });
  };

  const onImgLoad = ({ target: img }) => {
    setDimensions({
      height: img.offsetHeight,
      width: img.offsetWidth,
    });
    getFloorplan();
    getCameras();
  };
  useEffect(() => {
    getFloorplan();
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      {floorplanImg ? (
        <Image
          id="floorplan-img"
          ref={drop}
          onLoad={onImgLoad}
          src={floorplanImg}
          fluid
        ></Image>
      ) : (
        <Alert variant="warning">No floorplan uploaded.</Alert>
      )}
      {showSensors &&
        sensors.map(
          (sensor) =>
            sensor.location && (
              <OverlayTrigger
                placement="top"
                delay={{ show: 100, hide: 400 }}
                overlay={mapMarkerTooltip}
                popperConfig={{ sensorName: sensor.name }}
                key={sensor.name}
              >
                <HeatMapMarker
                  networkID={props.networkID}
                  cameraID={sensor.id}
                  isSet={sensor.location != null}
                  markerStyle={{
                    position: 'absolute',
                    top:
                      sensor.location.normalizedY * dimensions.height,
                    left:
                      sensor.location.normalizedX * dimensions.width,
                  }}
                ></HeatMapMarker>
              </OverlayTrigger>
            ),
        )}
    </div>
  );
};

export default LayoutImageView;
